import React from "react";
import {Col, Row} from "reactstrap";
import {MinecraftPrice} from "../datas/minecraftPrice";


const MineCraftPriceList = () => {

    return (
        <div className='minecraft_price_list_wrapper'>
            <div className='container-md'>
                <div className='minecraft_price_desc'>
                    <h3>강력하고, 편리하고, 혁신적인</h3>
                    <div style={{margin: '1rem 0'}}>
                        <h1>마인크래프트 서버 호스팅</h1>
                    </div>
                    <h4>대용량 디도스 방어, 트래픽, SSD 저장공간 모두 추가 비용 없이 무/제/한 제공</h4>
                </div>
                <div className='minecraft_price_list'>
                    <Row className='justify-content-between'>
                        {MinecraftPrice.map(price => {
                            return (
                                <Col xs={3}>
                                    <div className='minecraft_price_item'>
                                        <img width={82} src={`https://s3.server24.kr/panel/img/game/minecraft/stones/${price.img}`} alt={'price_img'}/>
                                        <div className='price_content'>
                                            <h2>{price.name}</h2>
                                            <div className='price_item_month'>
                                                <h1 className='d-inline'>{`₩ ${price.monthPrice}`}</h1>
                                                <span> / 30일</span>
                                            </div>
                                            <div className='price_performance'>
                                                <span><strong>{price.slots}</strong> Player Slots</span>
                                                <span><strong>{price.Ram}</strong> MB Ram</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default MineCraftPriceList;
