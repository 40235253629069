import React, {useState} from "react";
import {Button, UncontrolledCollapse} from 'reactstrap';
import faqArrow from '../asset/img/faq_arrow.svg';
import {mineFaqListLeft, mineFaqListRight} from "../datas/minecraftFaq";

const TicketFaq = () => {

    const [toggleFaq, setToggleFaq] = useState([]);
    const faqHandler = (index) => {
        if (toggleFaq.indexOf(index) !== -1) {
            setToggleFaq(toggleFaq.filter(item => item !== index))
        } else {
            setToggleFaq([...toggleFaq, index]);
        }

    }
    return (
        <div className='faq_item_wrapper'>
            <div className={'container-md'}>
                <div className='minecraft_faq_title'>
                    <h1>
                        FREQUENTLY ASKED QUESTIONS
                    </h1>
                </div>
                <div className='faq_list'>
                    <div className='faq_wrap'>
                        {mineFaqListLeft.map((item, index) => {
                            return (
                                <div className='faq_list_item' key={index}>
                                    <Button onClick={() => faqHandler(index)} id={`product_toggler_1_${index}`}>
                                        <div className='faq_item'>
                                            <span>{item.title}</span>
                                            <img className={toggleFaq.includes(index) ? 'rotate_arrow' : ''} src={faqArrow}/>
                                        </div>

                                    </Button>
                                    <UncontrolledCollapse toggler={`product_toggler_1_${index}`}>
                                        <div className='toggle_wrapper'>
                                            <div className='toggle_content_wrapper'>
                                                <p>
                                                    {item.content
                                                        .split('\n')
                                                        .map((line, index) => {
                                                            return (
                                                                <span key={index}>{line}<br/></span>
                                                            )
                                                        })}
                                                </p>
                                            </div>
                                        </div>
                                    </UncontrolledCollapse>
                                </div>
                            )
                        })}
                    </div>
                    <div className='faq_wrap'>
                        {mineFaqListRight.map((item, index) => {
                            return (
                                <div className='faq_list_item' key={index}>
                                    <Button onClick={() => faqHandler(index + 5)} id={`product_toggler_1_${index + 5}`}>
                                        <div className='faq_item'>
                                            <span>{item.title}</span>
                                            <img className={toggleFaq.includes(index + 5) ? 'rotate_arrow' : ''} src={faqArrow}/>
                                        </div>

                                    </Button>
                                    <UncontrolledCollapse toggler={`product_toggler_1_${index + 5}`}>
                                        <div className='toggle_wrapper'>
                                            <div className='toggle_content_wrapper'>
                                                <p>
                                                    {item.content
                                                        .split('\n')
                                                        .map((line, index) => {
                                                            return (
                                                                <span key={index}>{line}<br/></span>
                                                            )
                                                        })}
                                                </p>
                                            </div>
                                        </div>
                                    </UncontrolledCollapse>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TicketFaq;
