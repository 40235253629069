import React, {useState} from "react";
import {Col, Row} from "reactstrap";
import btnImg from '../asset/img/function_btn.png';
import function_img_1 from '../asset/img/function_img_1.png';


const MineCraftFunction = () => {

    const [imgNum, setImgNum] = useState(0);

    return (
        <div className='minecraft_function_wrapper'>
            <div className='container-md'>
                <div className='minecraft_function_title'>
                    <h1>클릭 몇번으로 마인크래프트 호스팅</h1>
                    <span>
                        아직도 힘들게 원격으로 직접 접속해서 구축하는 방식으로 서버를 관리하시나요? 이제는 별도 프로그램이나 복잡한 기술 없이, 웹 사이트 안에서 서버를 손쉽게 관리해보세요! 스마트폰에서도 가능합니다!
                    </span>
                </div>
                <div className='function_list'>
                    <Row>
                        <Col xs={3}>
                            <div className='function_btn_wrapper'>
                                <div
                                    onClick={() => setImgNum(0)}
                                    className={imgNum === 0 ? 'function_btn_item select' : 'function_btn_item'}>
                                    <img width={30} src={btnImg} alt={'select_function_btn'}/>
                                    <span>
                                    서버 생성
                                </span>
                                </div>
                                <div
                                    onClick={() => setImgNum(1)}
                                    className={imgNum === 1 ? 'function_btn_item select' : 'function_btn_item'}>
                                    <img width={30} src={btnImg} alt={'select_function_btn'}/>
                                    <span>
                                    서버 관리
                                </span>
                                </div>
                                <div
                                    onClick={() => setImgNum(2)}
                                    className={imgNum === 2 ? 'function_btn_item select' : 'function_btn_item'}>
                                    <img width={30} src={btnImg} alt={'select_function_btn'}/>
                                    <span>
                                    플러그인
                                </span>
                                </div>
                                <div
                                    onClick={() => setImgNum(3)}
                                    className={imgNum === 3 ? 'function_btn_item select' : 'function_btn_item'}>
                                    <img width={30} src={btnImg} alt={'select_function_btn'}/>
                                    <span>
                                    MANAGE YOUR SERVER
                                </span>
                                </div>
                            </div>
                        </Col>
                        <Col xs={9}>
                            <div className='function_img_wrapper'>
                                <img src={function_img_1} alt={'function_img'}/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default MineCraftFunction;
