import React from "react";


const DiscountSection = () => {

    return (
        <div className='discount_section'>
            <div className='container-md'>
                <div className='discount_txt_wrapper'>
                    <div className='discount_disc'>
                        <h1>지금 가입하시면,</h1>
                        <span>10,000원 상당의 포인트 즉시 지급해드립니다!</span>
                    </div>
                    <div>
                        <button id={'now_go_btn'}>지금 받으러 가기</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default DiscountSection;
