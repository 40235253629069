import React from "react";
import {Col, Row} from "reactstrap";
import planImg1 from '../asset/img/main_plan_img_1.png';
import planImg2 from '../asset/img/main_plan_img_2.png';
import planImg3 from '../asset/img/main_plan_img_3.png';
import planImg4 from '../asset/img/main_plan_img_4.png';
import {MinecraftPrice} from "../datas/minecraftPrice";


const DashBoardPriceSection = () => {

    return (
        <div className='dashboard_price_section'>
            <div className='container-md'>
                <div className='price_title'>
                    <h1>마인크래프트 요금제</h1>
                    <span>서버 호스팅이 처음이신가요? 걱정마세요! 나중에 언제든지 인원수도 늘리고 줄일 수 있습니다!<br /> 지금은 서버에 접속할 최대 인원수만 결정하시면 됩니다!</span>
                </div>
                <div className='price_list'>
                    <Row>
                        <Col xs={3}>
                            <div
                                style={{backgroundImage: `url(${planImg1})`}}
                                className='price_item'>
                                <div className='price_content'>
                                    <div className='price_content_title'>
                                        <h1>{MinecraftPrice[0].name}</h1>
                                        <span>최대 <strong>{MinecraftPrice[0].slots}</strong>명 까지 동시 접속 가능</span>
                                    </div>
                                    <div className='price_content_main'>
                                        <div className='bill_info'>
                                            ₩ <h1>{MinecraftPrice[0].monthPrice.toLocaleString()}</h1>원<small> / 30일</small>
                                        </div>
                                    </div>
                                    <div className='price_go_btn'>
                                        <button>ORDER NOW</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={3}>
                            <div
                                style={{backgroundImage: `url(${planImg2})`}}
                                className='price_item'>
                                <div className='price_content'>
                                    <div className='price_content_title'>
                                        <h1>{MinecraftPrice[2].name}</h1>
                                        <span>최대 <strong>{MinecraftPrice[2].slots}</strong>명 까지 동시 접속 가능</span>
                                    </div>
                                    <div className='price_content_main'>
                                        <div className='bill_info'>
                                            ₩ <h1>{MinecraftPrice[2].monthPrice.toLocaleString()}</h1>원<small> / 30일</small>
                                        </div>
                                    </div>
                                    <div className='price_go_btn'>
                                        <button>ORDER NOW</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={3}>
                            <div
                                style={{backgroundImage: `url(${planImg3})`}}
                                className='price_item'>
                                <div className='price_content'>
                                    <div className='price_content_title'>
                                        <h1>{MinecraftPrice[5].name}</h1>
                                        <span>최대 <strong>{MinecraftPrice[5].slots}</strong>명 까지 동시 접속 가능</span>
                                    </div>
                                    <div className='price_content_main'>
                                        <div className='bill_info'>
                                            ₩ <h1>{MinecraftPrice[5].monthPrice.toLocaleString()}</h1>원<small> / 30일</small>
                                        </div>
                                    </div>
                                    <div className='price_go_btn'>
                                        <button>ORDER NOW</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={3}>
                            <div
                                style={{backgroundImage: `url(${planImg4})`}}
                                className='price_item'>
                                <div className='price_content'>
                                    <div className='price_content_title'>
                                        <h1>{MinecraftPrice[9].name}</h1>
                                        <span>최대 <strong>{MinecraftPrice[9].slots}</strong>명 까지 동시 접속 가능</span>
                                    </div>
                                    <div className='price_content_main'>
                                        <div className='bill_info'>
                                            ₩ <h1>{MinecraftPrice[9].monthPrice.toLocaleString()}</h1>원<small> / 30일</small>
                                        </div>
                                    </div>
                                    <div className='price_go_btn'>
                                        <button>ORDER NOW</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/*<div>*/}
                    {/*    <button id={'now_go_btn'}>지금 받으러 가기</button>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}

export default DashBoardPriceSection;
