import React from "react";
import {Col, Row} from "reactstrap";
import page from '../asset/img/features/page.svg';
import stopwatch from '../asset/img/features/stopwatch.svg';
import cpu from '../asset/img/features/cpu.svg';
import shield from '../asset/img/features/encrypted.svg';
import unlimited from '../asset/img/features/unlimited.svg';
import click from '../asset/img/features/click.svg';

const FeaturesSection = () => {

    return (
        <div className='features_section'>
            <div className='container-md'>
                <div className='features_section_title'>
                    <h1>서버24에서 마인크래프트 서버를 운영한다면?</h1>
                </div>
                <Row className='features_box_wrapper'>
                    <Col xs={4} className='features_box'>
                        <div className='features_content'>
                            <Row className='align-items-center'>
                                <Col xs={2} className='text-center'>
                                    <img width={50} src={shield}/>
                                </Col>
                                <Col xs={8}>
                                    <h4>추가비용 없이<br/>DDoS 공격 무제한 방어</h4>
                                </Col>
                            </Row>
                            <div className='features_desc'>
                                <p>
                                    제공되는 서비스는 최대 80Tbps+ 이상의 네트워크 가용성을 보유한 CDN77과 긴밀하게 협력하여 나날이 새로워지는 신종 취약점 공격과 대용량 디도스 공격을 추가 비용 없이 즉시 완화합니다.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={4} className='features_box'>
                        <div className='features_content'>
                            <Row className='align-items-center'>
                                <Col xs={2} className='text-center'>
                                    <img width={50} src={page}/>
                                </Col>
                                <Col xs={8}>
                                    <h4>원격 접속이 필요없는<br/> 혁신적인 서버 관리 방식</h4>
                                </Col>
                            </Row>
                            <div className='features_desc'>
                                <p>
                                    아직도 힘들게 원격으로 직접 접속해서 구축하는 방식으로 서버를 관리하시나요? 이제는 별도 프로그램이나 복잡한 기술 없이, 웹 사이트 안에서 서버를 손쉽게 관리해보세요! 스마트폰에서도 가능합니다!
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={4} className='features_box'>
                        <div className='features_content'>
                            <Row className='align-items-center'>
                                <Col xs={2} className='text-center'>
                                    <img width={50} src={click}/>
                                </Col>
                                <Col xs={8}>
                                    <h4>다양한 버킷과 모드들을<br/> 클릭 몇 번으로 손쉽게</h4>
                                </Col>
                            </Row>
                            <div className='features_desc'>
                                <p>
                                    기본적으로 유명한 모드팩과 다양한 버킷들을 클릭 몇 번만에 바로 사용할 수 있습니다! 혹시 찾으시는 모드가 없으신가요? 가지고 계신 파일로도 직접 구동하실 수 있습니다!
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={4} className='features_box'>
                        <div className='features_content'>
                            <Row className='align-items-center'>
                                <Col xs={2} className='text-center'>
                                    <img width={50} src={cpu}/>
                                </Col>
                                <Col xs={8}>
                                    <h4>5.0GHz+ 강력한 <br/> 고성능 컴퓨팅 인프라</h4>
                                </Col>
                            </Row>
                            <div className='features_desc'>
                                <p>
                                    모든 서버는 최고의 성능을 위해 싱글 코어 성능이 뛰어난 5.0GHz 내외의 고성능 CPU와 안정적인 ECC DDR4 메모리,  고성능 NVMe SSD 그리고 최대 40GbE 네트워크를 제공합니다.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={4} className='features_box'>
                        <div className='features_content'>
                            <Row className='align-items-center'>
                                <Col xs={2} className='text-center'>
                                    <img width={50} src={unlimited}/>
                                </Col>
                                <Col xs={8}>
                                    <h4>무제한 디스크 공간 <br/> 무제한 제공</h4>
                                </Col>
                            </Row>
                            <div className='features_desc'>
                                <p>
                                    가능한 최상의 경험을 제공하기 위해 서버가 사용하는 디스크 공간 및 기타 여러 리소스에 대한 무제한 액세스. 제한이 아닌 플레이에 집중하세요!
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={4} className='features_box'>
                        <div className='features_content'>
                            <Row className='align-items-center'>
                                <Col xs={2} className='text-center'>
                                    <img width={50} src={stopwatch}/>
                                </Col>
                                <Col xs={8}>
                                    <h4>초고속 SSD <br/>저장공간 무제한 제공</h4>
                                </Col>
                            </Row>
                            <div className='features_desc'>
                                <p>
                                    최상의 서버환경 구성을 위해서는 CPU, 메모리, 저장소의 성능이 모두 중요합니다! 접속자에게 최상의 경험을 제공하기 위해 서버24에서 제공하는 고성능 SSD 저장소 공간을 추가 비용 없이 가용공간 내 무제한으로 이용해보세요!
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>

    )
}


export default FeaturesSection;
