import {BrowserRouter as Router} from 'react-router-dom';
import Layout from "./layouts/Layout";

function App() {
  return (
      <Router>
        <Layout/>
      </Router>
  );
}

export default App;
