export const mineFaqListLeft = [
    {
        index: '1',
        title: '서비스 신청 후 접속까지는 얼마나 걸리나요?',
        content: '제공해드리는 모든 서비스는 신청 후 자동으로 준비되며, 몇 분 뒤부터 바로 사용이  가능합니다.'
    }, {
        index: '2',
        title: '정말 무제한으로 디도스 공격이 방어되나요?',
        content: '네, 그렇습니다!\n' +
            '\n' +
            '모든 서비스는 최대 80Tbps+ 이상의 네트워크 가용성을 보유한 CDN77과 긴밀하게 협력하여 나날이 새로워지는 신종 취약점 공격과 대용량 디도스 공격을 추가 비용 없이 즉시 완화합니다.'
    }, {
        index: '3',
        title: '서버에 모드나 플러그인을 추가로 설치할 수 있나요?',
        content: '네, 그렇습니다!\n' +
            '\n' +
            '서버24에서 기본적으로 다양한 모드와 플러그인을 제공해드리고 있지만, 이외에 다른 모드나 플러그인이 필요하시다면 언제든지 서버24 관리 페이지에서 사용하실 모드나 플러그인 파일을 직접 업로드하고 수정하실 수 있습니다!\n'
    }, {
        index: '4',
        title: '나중에 요금제를 업그레이드할 수 있나요?',
        content: '네, 언제든지 사용중이신 요금제를 업그레이드하실 수 있습니다!\n' +
            '\n' +
            '혹시 메모리나 최대 접속 가능한 인원수를 더 늘리셔야하나요?\n상위 요금제와의 차액만큼을 추가로 결제하시면 언제든지 요금제를 바로 업그레이드 하실 수 있습니다!'
    }
]
export const mineFaqListRight = [
    {
        index: '5',
        title: '서비스를 무료로 이용할 수 있나요?',
        content: '네, 그렇습니다!\n' +
            '가입 후 휴대폰번호를 인증하시면 현금처럼 사용하실 수 있는 10,000원 상당의 포인트를 즉시 지급해드립니다! 무료로 지급된 포인트로 게임 서버 호스팅뿐만 아니라 cPanel 웹 호스팅과 가상 서버 호스팅 등을 포함해 서버24에서 제공하는 모든 서비스를 체험하실 수 있습니다!'
    }, {
        index: '6',
        title: 'Rust, FiveM 같은 다른 게임도 가능한가요?',
        content: '지금은 안되지만, 마인크래프트를 시작으로 더욱 많은 게임 서버를 손쉽게 운영하실 수 있도록 저희가 열심히 준비하고 있습니다!\n' +
            '\n' +
            '현재 저희가 준비하고 있는 게임 서비스를 미리 알려드리겠습니다!\n\n' +
            '7 Days to Die, Avorion, ARK: Survival Evolved, ARMA 3, Counter-Strike Global Offensive, Factorio, Garrys Mod, Left 4 Dead 2, Minecraft Bedrock/Java/Pocket Edition, Rust, Project Zomboid, Satisfactory, Space Engineers, StarBound, Terraria, Team Fortress 2, The Forest, Valheim'
    }, {
        index: '7',
        title: '서비스 신청은 어디에서 하나요?',
        content: '\n' +
            '오른쪽 상단메뉴의 "서비스 신청" 페이지에서 회원가입 하신 뒤에 서비스를 신청하실 수 있습니다.\n' +
            '\n' +
            '서비스를 이용하시면서 자세한 사용방법이 궁금하시거나 문제가 발생하셨다면, 언제든 콘솔페이지 내 티켓문의로 연락주시면 친절하게 안내해드리겠습니다.'
    }, {
        index: '8',
        title: '서버는 어디에서 가동되고 있나요?',
        content: '현재 제공해드리는 서비스 국가는 총 두 곳으로, 대한민국(서울)과 일본(도쿄)에서 제공해드리고 있습니다.\n\n디도스 공격을 방어하고 싶으시다면 일본(도쿄)지역을 선택해주시기 바랍니다!\n\n' + '일본(도쿄) Ping 테스트하기 (클릭)'
    }
]
