import React from "react";
import DiscountSection from "./DiscountSection";
import DashboardMainSection from "./DashboardMainSection";
import FeaturesSection from "./FeaturesSection";
import DashBoardPriceSection from "./DashBoardPriceSection";
import MineCraftFunction from "../minecraft/MineCraftFunction";


const DashBoard = () => {

    return (
        <div className='dashboard_wrapper'>
            <DiscountSection/>
            <DashboardMainSection/>
            <FeaturesSection/>
            <DashBoardPriceSection/>
            <MineCraftFunction />
            {/*<ControlPanelSection/>*/}
        </div>
    )
}

export default DashBoard;
