import React from "react";
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import {RouteIf} from "../hoc/RouteIf";
import DashBoard from "../dashboard/DashBoard";
import NavHeader from '../nav/NavHeader';
import NavFooter from "../nav/NavFooter";
import MineCraftHosting from "../minecraft/MineCraftHosting";

const Layout = () => {


    return (
        <Router>
            <NavHeader/>
            <Switch>
                <RouteIf exact path="/" option={null} component={DashBoard}/>
                <RouteIf exact path="/minecraft" option={null} component={MineCraftHosting}/>

            </Switch>
            <NavFooter/>
        </Router>
    )

}


export default Layout;
