import React from "react";
import main_sec_img from '../asset/img/minecraftMain.png';

const MineCraftMainSection = () => {
    return (
        <div className='dashboard_main_section'
             style={{backgroundImage: `url(${main_sec_img})`}}>
            <div className='black_back'>
                <div className='container-md'>
                    <div className='minecraft_main_section_txt'>
                        <h1>마인크래프트 서버 호스팅</h1>
                        <h2>Minecraft Java & Bedrock Edition 완벽 지원</h2>
                        <button>서비스 신청</button>
                    </div>
                    <div className='main_section_footer'>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MineCraftMainSection;
