import React from "react";
import {Col, Row} from "reactstrap";
import panelImg from '../asset/img/panelhome.png';

const ControlPanelSection = () => {


    return (
        <div className='control_panel_section'>
            <div className='container-md'>
                <Row className='align-items-center'>
                    <Col xs={4}>
                        <div className='control_panel_txt_wrapper'>
                            <h3>MULTICRAFT CONTROL PANEL</h3>
                            <p>
                                We offer a highly customized version of the Multicraft control panel. Managing your server is incredibly easy and all of the features are intuitive and well documented.
                            </p>
                            <div className='control_panel_features'>
                                <span>
                                    Manage all of your Minecraft servers
                                    </span>
                                <span>
                                Full FTP File Access
                                </span>
                                <span>
                                Access your console and chat
                                </span>
                            </div>
                            <button id='control_panel_btn'>
                                LEARN MORE
                            </button>
                        </div>
                    </Col>
                    <Col xs={8}>
                        <div className='control_panel_img_wrapper'>
                            <img src={panelImg}/>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default ControlPanelSection;
