import React from "react";
import {Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import ApartmentIcon from '@mui/icons-material/Apartment';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';


const NavFooter = () => {

    return (
        <div className='nav_footer_wrapper'>
            <div className='nav_footer_map container-md'>
                <Row>
                    <Col xs={3}>
                        <div className='footer_item'>
                            <div className='item_title'>
                                <h3>GAME.SERVER24.KR</h3>
                            </div>
                            <span>상호명: 데이터크레딧</span>
                            <span>대표자: 한찬희</span>
                            <span>사업자등록번호: 329-12-01903</span>
                            <span>통신판매업신고번호: 2022-경기하남-0519</span>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <div className='footer_item'>
                            <div className='item_title'>
                                <h3>Sitemap</h3>
                            </div>
                            <ul>
                                <li>
                                    <div style={{cursor: 'pointer'}} onClick={() => window.open('https://server24.kr/privacy')}>
                                        개인정보처리방침
                                    </div>
                                </li>
                                <li>
                                    <div style={{cursor: 'pointer'}} onClick={() => window.open('https://server24.kr/tos')}>
                                        이용약관 및 환불정책
                                    </div>
                                </li>
                                <li>
                                    <div style={{cursor: 'pointer'}} onClick={() => window.open('https://server24.kr/donot-email')}>
                                        이메일주소 수집거부
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <div className='footer_item'>
                            <div className='item_title'>
                                <h3>Service</h3>
                            </div>
                            <Link to={'/minecraft'}>
                                <span>
                                MINECRAFT HOSTING
                                    </span>
                            </Link>
                            <div style={{cursor : 'pointer'}} onClick={() => window.open('https://server24.kr')}>
                                <span>
                                WEB HOSTING
                                    </span>
                            </div>
                            <div style={{cursor : 'pointer'}}  onClick={() => window.open('https://server24.kr')}>
                                <span>
                                SERVER HOSTING
                                    </span>
                            </div>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <div className='footer_item'>
                            <div className='item_title'>
                                <h3>Contact Us</h3>
                            </div>
                            <Row className='align-items-center'>
                                <Col xs={1}>
                                    <ApartmentIcon style={{color : 'white'}}/>
                                </Col>
                                <Col xs={11}>
                                    <span>경기도 하남시 미사강변중앙로7번안길 25 하남미사유테크밸리 D동 605호</span>
                                </Col>
                            </Row>
                            <Row className='align-items-center mt-2'>
                                <Col xs={1}>
                                    <EmailIcon style={{color : 'white'}}/>
                                </Col>
                                <Col xs={11}>
                                    <span>support@server24.kr</span>
                                </Col>
                            </Row>
                            <Row className='align-items-center mt-2'>
                                <Col xs={1}>
                                    <LocalPhoneIcon style={{color : 'white'}}/>
                                </Col>
                                <Col xs={11}>
                                    <span>070-8672-1035</span>
                                </Col>
                            </Row>


                        </div>
                    </Col>
                </Row>
            </div>
            <div className='footer_foot'>
                <div className='container-md'>
                    <span className="copyright">Copyrights 2021 © Server24 &amp; DataCredit All Rights Reserved.</span>
                </div>
            </div>
        </div>
    )
}


export default NavFooter;
