import Swal from "sweetalert2";


export const isLogin = function () {
    const token = localStorage.getItem('auth_login');
    return !!token;
};


export const notificationAlert = (type, txt) => {
    Swal.fire({
        title: type,
        text: txt,
        confirmButtonText: '닫기'
    })
}

export const moveNotificationAlert = (type, txt, location) => {
    Swal.fire({
        allowOutsideClick: () => window.location.href = location,
        title: type,
        text: txt,
        confirmButtonText: '닫기'
    }).then(result => {
        if (result.isConfirmed) {
            window.location.href = location;
        }
    })
}

