import React from "react";
import DiscountSection from "../dashboard/DiscountSection";
import ControlPanelSection from "../dashboard/ControlPanelSection";
import MineCraftPriceList from "./MineCraftPriceList";
import MineCraftFaq from "./MineCraftFaq";
import MineCraftMainSection from "./MineCraftMainSection";


const MineCraftHosting = () => {

    return (
        <div className='minecraft_hosting_wrapper'>
            <DiscountSection/>
            <MineCraftMainSection/>
            <MineCraftPriceList/>
            {/*<MineCraftFunction/>*/}
            <MineCraftFaq/>
            <ControlPanelSection/>
        </div>
    )
}

export default MineCraftHosting;
