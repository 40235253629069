import React from "react";
import mainLogo from '../asset/img/game_main_logo.png';
import {Link} from "react-router-dom";

const NavHeader = () => {
    return (
        <div className='header_wrapper'>
            <div className='header_box'>
                <div onClick={() => window.location.href = '/'} className='header_main_logo'>
                    <img src={mainLogo} alt={'main_logo'}/>
                </div>
                <div className='nav_item_wrapper'>
                    <div className='nav_item'>
                        <Link to={'/'}>
                            <span>
                            홈
                            </span>
                        </Link>
                    </div>
                    <div className='nav_item'>
                        <Link to={'/minecraft'}>
                            <span>
                            마인크래프트 호스팅
                                </span>
                        </Link>
                    </div>
                    <div className='nav_item' onClick={()=>window.open('https://server24.kr')}>
                        <div>
                            <span>
                            웹 호스팅
                                </span>
                        </div>
                    </div>
                    <div className='nav_item' onClick={()=>window.open('https://server24.kr')}>
                        <div>
                            <span>
                            서버 호스팅
                                </span>
                        </div>
                    </div>
                </div>
                <div className='nav_support_wrapper'>
                    <button>
                        LOGIN
                    </button>
                </div>
            </div>
        </div>
    )
}

export default NavHeader;
