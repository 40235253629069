import React from "react";
import main_sec_img from '../asset/img/main_section_back.png';
import {Col, Row} from "reactstrap";
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const DashboardMainSection = () => {
    return (
        <div className='dashboard_main_section'
             style={{backgroundImage: `url(${main_sec_img})`}}>
            <div className='black_back'>
                <div className='container-md'>
                    <div className='main_section_txt'>
                        <h1>웹으로 손쉽게 관리하는<br />
                            신개념 마인크래프트 호스팅</h1>
                        <div className='main_section_features'>
                            <Row>
                                <Col xs={6}>
                                    <span className='d-block'><CheckBoxIcon/>Minecraft Java & Bedrock Edition 완벽 지원</span>
                                    <span className='d-block'><CheckBoxIcon/>클릭 몇 번만에 다양한 Modpacks & Plugins 설치</span>
                                    <span className='d-block'><CheckBoxIcon/>24시간 / 7일 / 365일 연중무휴 한국어 기술 지원</span>
                                </Col>
                                <Col xs={6}>
                                    <span className='d-block'><CheckBoxIcon/>추가비용 없이 DDoS 공격 무제한 방어</span>
                                    <span className='d-block'><CheckBoxIcon/>원격 접속이 필요없는 혁신적인 서버 관리 방식</span>
                                    <span className='d-block'><CheckBoxIcon/>초고속 NVMe SSD 저장공간 무제한 제공</span>
                                </Col>
                                <Col xs={12}>
                                    <div style={{padding: '2rem 0'}}>
                                        <button id='main_section_go_btn'>
                                            <strong>4코어? 8기가? 이제는 접속할 인원수 만큼만 결제하세요</strong>
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='main_section_footer'>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardMainSection;
