export const MinecraftPrice = [
    {
        name: 'GRASS',
        slots: 10,
        monthPrice: 7000,
        Ram: 1024,
        img: "grass.png"
    },
    {
        name: 'WOOD',
        slots: 20,
        monthPrice: 15000,
        Ram: 2048,
        img: "wood.png"
    },
    {
        name: 'STONE',
        slots: 30,
        monthPrice: 25000,
        Ram: 3072,
        img: "stone.png"
    },
    {
        name: 'COAL',
        slots: 40,
        monthPrice: 40000,
        Ram: 4096,
        img: "coal.png"
    },
    {
        name: 'IRON',
        slots: 50,
        monthPrice: 60000,
        Ram: 6144,
        img: "iron.png"
    },
    {
        name: 'COPPER',
        slots: 60,
        monthPrice: 80000,
        Ram: 8192,
        img: 'copper.png'
    },
    {
        name: 'GOLD',
        slots: 70,
        monthPrice: 100000,
        Ram: 10240,
        img: 'gold.png'
    },
    {
        name: 'DIAMOND',
        slots: 80,
        monthPrice: 150000,
        Ram: 12288,
        img: 'diamond.png'
    },
    {
        name: 'EMERALD',
        slots: 90,
        monthPrice: 200000,
        Ram: 14336,
        img: 'emerald.png'
    },
    {
        name: 'REDSTONE',
        slots: 100,
        monthPrice: 300000,
        Ram: 16384,
        img: 'redstone.png'
    },
    {
        name: 'LAPIS',
        slots: 150,
        monthPrice: 500000,
        Ram: 32768,
        img: 'lapis.png'
    },
    {
        name: 'OBSIDIAN',
        slots: 200,
        monthPrice: 650000,
        Ram: 65536,
        img: 'obsidian.png'
    },
    {
        name: 'BEDROCK',
        slots: 250,
        monthPrice: 990000,
        Ram: 81920,
        img: 'bedrock.png'
    }
]
